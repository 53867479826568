.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.Input-label{
  margin-right: 10px;
}

.container {
  width: 500px;
  clear: both;
}

.container input {
  width: 100%;
  clear: both;
}